import React from "react";
import Image from "next/image";
import Link from "next/link";
import Logo from "./../../public/Logo-dark.png";
import WhiteLogo from "./../../public/Logo-white.png";
import { v4 as uuidv4 } from "uuid";
import { useRouter } from "next/router";
import {
  isChildrenPageActive,
  isPageActive,
  isParentPageActive,
} from "../../utils/daynamicNavigation";
import { useEffect, useState } from "react";
import DarkMode from "../mode/DarkMode";

import { useDispatch, useSelector } from "react-redux";
import { profileModalShow, manageModalShow } from "../../redux/counterSlice";

import { FaListUl, FaSignOutAlt, FaStar } from "react-icons/fa";
import { FaCartShopping } from "react-icons/fa6";
import { cartDrawerToggle } from "../../redux/cartSlice";
import { ConnectWallet, useWallet } from "@thirdweb-dev/react";
import { useTheme } from "next-themes";


export default function Header04() {

  const [toggle, setToggle] = useState(false);
  const [isCollapse, setCollapse] = useState(null);
  const [isScroll, setScroll] = useState(false);



  // sticky menu
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 4) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    });
  }, []);

  // window resize
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 1024) {
        setToggle(false);
      }
    });
  });

  const route = useRouter();
  /* -------------------------------------------------------------------------- */
  /*                            daynamic navigations                            */
  /* -------------------------------------------------------------------------- */
  const home = [
    {
      id: uuidv4(),
      name: "Domains",
      path: "/domains",
      // pages: [
      //   {
      //     id: uuidv4(),
      //     name: "Coming Soon",
      //     path: null
      //   },
      // ]
    },
    {
      id: uuidv4(),
      name: "Wallet",
      path: "/wallet",
      // pages: [
      //   {
      //     id: uuidv4(),
      //     name: "Connect",
      //     path: "/",
      //   },
      //   {
      //     id: uuidv4(),
      //     name: "Create",
      //     path: "/",
      //   },
      //   {
      //     id: uuidv4(),
      //     name: "Secure",
      //     path: "/",
      //   }
      // ]
    },
    {
      id: uuidv4(),
      name: "API",
      path: "/docs/api"
    },
    {
      id: uuidv4(),
      name: <span>AI &nbsp; Domain</span>,
      path: "/ai-domains"
    }
    // {
    //   id: uuidv4(),
    //   name: "Resources",
    //   pages: [
    //     {
    //       id: uuidv4(),
    //       name: "Blog",
    //       path: "/blogs",
    //     },
    //     {
    //       id: uuidv4(),
    //       name: "News",
    //       path: "/news",
    //     }
    //   ]
    // },
    // {
    //   id: uuidv4(),
    //   name: "Giveaways",
    //   path: "/giveaways",
    // },


  ]




  const mobileCollapse = (id) => {
    if (isCollapse === id) {
      return setCollapse(null);
    }
    setCollapse(id);
  };
  const dispatch = useDispatch();

  const isLoaded = true

  const { cartItems } = useSelector((state) => state.cart);


  const { theme } = useTheme();
  const isWallet = useWallet()


  return <>

    {/* main desktop menu sart*/}
    <header
      className={`js-page-header fixed top-0 z-20 w-full backdrop-blur transition-colors ${isScroll ? "js-page-header--is-sticky" : ""
        }`}
    >
      {!isScroll && <div className="bg-gradient-to-r from-hotPink via-purplish to-accent  text-center p-2">
        <p className="text-[13px] md:text-lg font-semibold text-white uppercase">
          🏖️ Experience Our Alpha Launch 🚀
        </p>
      </div>}
      <div className="flex items-center px-6 py-6 xl:px-24 ">
        <Link className="shrink-0" href="/" >

        <div className="dark:hidden">
            <Image
              src={WhiteLogo}
              height={28}
              width={90}
              alt="Netzylo"
              className=" h-auto "
            />
          </div>
          <div className="hidden dark:block">
            <Image
              src={ Logo}
              height={28}
              width={90}
              alt="Netzylo"
            />
          </div>

        </Link>
        {/* End  logo */}

        <div className="js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent w-full">
          <nav className="navbar w-full mt-24  lg:mt-0">
            <ul className="flex flex-col justify-center lg:flex-row">
              {/* home */}
              {home.map((page, index) => {
                return (
                  <li className="js-nav-dropdown group relative">
                    {!page.pages ? <Link href={page.path} className="dropdown-toggle text-jacarta-900 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full relative ">

                      <span
                        className={
                          isPageActive(home, route.asPath)
                            ? "text-accent  dark:text-accent"
                            : ""
                        }
                      >
                        {page.name}
                      </span>
                    </Link > :

                      <Link href={page.path} className="dropdown-toggle text-jacarta-900 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full">

                        <span
                          className={
                            isPageActive(home, route.asPath)
                              ? "text-accent  dark:text-accent"
                              : ""
                          }
                        >
                          {page.name}
                        </span>
                      </Link >
                    }
                    {/* {index === 1 && <ul className="dropdown-menu group-hover:visible lg:invisible -left-6 top-[85%] z-[9999999] hidden  whitespace-nowrap rounded-xl bg-white transition-all will-change-transform group-hover:opacity-100 dark:bg-jacarta-800 lg:absolute lg:!grid lg:translate-y-4 lg:py-8 lg:px-5 lg:opacity-0 lg:shadow-1xl lg:group-hover:translate-y-2">
                      {home[1].pages?.map((page) => (
                        <li key={page.id}>
                          <Link
                            href={page.path}
                            className="dark:hover:bg-jacarta-600  hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center rounded-xl px-5 py-2 transition-colors justify-between z-[9999999] "
                          >

                            <span
                              className={`font-display ${isChildrenPageActive(page.path, route.asPath)
                                ? "text-accent dark:text-accent"
                                : "text-jacarta-700"
                                } text-sm dark:text-white`}
                            >
                              {page.name}
                            </span>

                          </Link>
                        </li>
                      ))}
                    </ul>} */}
                    {/* {index === 3 && <ul className="dropdown-menu group-hover:visible lg:invisible -left-6 top-[85%] z-[9999999] hidden  whitespace-nowrap rounded-xl bg-white transition-all will-change-transform group-hover:opacity-100 dark:bg-jacarta-800 lg:absolute lg:!grid lg:translate-y-4 lg:py-8 lg:px-5 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2">
                      <li >

                        <span
                          className="  hover:text-accent focus:text-accent  flex items-center rounded-xl px-2  transition-colors justify-between z-[9999999] cursor-default"
                        >
                          <span
                            className={`font-display flex gap-1
                                ? "text-accent dark:text-accent"
                                : "text-jacarta-700"
                                } text-sm dark:text-white`}
                          >

                            <FaStar style={{ fill: "url(#blue-gradient)" }} />

                            Coming Soon
                          </span>
                        </span>
                      </li>
                    </ul>} */}
                  </li>
                )
              })}
              <svg width="1em" height="1em">
                <linearGradient id="blue-gradient" x1="100%" y1="100%" x2="0%" y2="0%">
                  <stop stopColor="#FF69B4" offset="0%" />
                  <stop stopColor="#804AE4" offset="50%" />
                  <stop stopColor="#02f6d1" offset="100%" />
                </linearGradient>
              </svg>
            </ul>
          </nav>


          {/* End menu for desktop */}
        </div>
        {/* header menu conent end for desktop */}

        <div className="hidden  justify-end lg:flex">

          {isLoaded ? <>
            {/* <!-- CTA --> */}
            <ConnectWallet modalTitleIconUrl='/logo-token.png' modalSize={"compact"} auth={{ loginOptional: true, onLogout: () => route.push('/') }} modalTitle='Connect Wallet'
              welcomeScreen={{
                img: {
                  src: "/splash.png",
                  width: 200,
                  height: 200,
                },
              }}
              showThirdwebBranding={false} btnTitle='Connect' className={`${!isWallet ? "ai-btn" : ""}   !text-[16px]  !rounded-md !font-semibold   !h-[48px] !py-2 !mr-2 ${isWallet ? "!flex " : "!hidden"} `}

              theme={theme === 'dark' || theme === 'system' ? "dark" : "light"}

            />
            < div
              onClick={() => dispatch(profileModalShow())}
              className="border-jacarta-100   hover:bg-accent dark:hover:bg-accent mr-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15] cursor-pointer"
              aria-label="profile"
            >
              <FaListUl />
            </div>


            <div onClick={() => dispatch(cartDrawerToggle())}
              className="border-jacarta-100  relative hover:bg-accent dark:hover:bg-accent mr-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15] cursor-pointer"
              aria-label="Cart" >
              <FaCartShopping />
              {cartItems.length > 0 && <div className="bg-accent w-4 h-4 text-sm absolute top-0 right-0 rounded-full text-center text-jacarta-900">{cartItems.length}</div>}
            </div>


            {/* <div onClick={() => dispatch(manageModalShow())} className="border-jacarta-100   hover:bg-accent dark:hover:bg-accent flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15] cursor-pointer relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
                className="fill-jacarta-900 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
              </svg>
            </div> */}

            {/* <MenuList /> */}
            <DarkMode />
          </> :
            <div className="animate-pulse bg-jacarta-200 dark:bg-jacarta-700 rounded-md p-4  min-w-[10rem]">
            </div>}
        </div>
        {/* End right marketplace btn */}

        <div className="ml-auto flex justify-center lg:hidden">
          {isLoaded ? <>
            {/* <!-- CTA --> */}

            < div
              onClick={() => dispatch(profileModalShow())}
              className="border-jacarta-100   hover:bg-accent dark:hover:bg-accent mr-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15] cursor-pointer"
              aria-label="profile"
            >
              <FaListUl />
            </div>

            <div onClick={() => dispatch(cartDrawerToggle())}
              className="border-jacarta-100  relative hover:bg-accent dark:hover:bg-accent mr-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15] cursor-pointer"
              aria-label="Cart" >
              <FaCartShopping />
              {cartItems.length > 0 && <div className="bg-accent w-4 h-4 text-sm absolute top-0 right-0 rounded-full text-center text-jacarta-900">{cartItems.length}</div>}
            </div>



            {/* <div onClick={() => dispatch(manageModalShow())} className="border-jacarta-100   hover:bg-accent dark:hover:bg-accent flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15] cursor-pointer relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
                className="fill-jacarta-900 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
              </svg>
            </div> */}

            <DarkMode />

          </> :
            <div className="animate-pulse bg-jacarta-200 dark:bg-jacarta-700 rounded-md p-4  min-w-[10rem]">
            </div>}
        </div>
        {/* End mobile toggle button */}

      </div>
    </header >
    {/* main desktop menu end */}

    {/* start mobile menu and it's other materials  */}
    <div
      className={`lg:hidden js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-20 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent ${toggle ? "nav-menu--is-open" : "hidden"
        }`}
    >
      <div className="t-0 dark:bg-jacarta-800 fixed left-0 z-10 flex w-full items-center justify-between bg-white p-6 lg:hidden">
        <div className="dark:hidden">
          <Image
            src={Logo}
            height={28}
            width={130}
            alt="Xhibiter | NFT Marketplace"
            className=" h-auto "
          />
        </div>

        <div className="hidden dark:block">
          <Image
            src={WhiteLogo}
            height={28}
            width={130}
            alt="Xhibiter | NFT Marketplace"
          />
        </div>

        <button
          className="js-mobile-close border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
          onClick={() => setToggle(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={24}
            height={24}
            className="fill-jacarta-900 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
          </svg>
        </button>

      </div>
      {/* mobile menu top header content */}

      <form action="search" className="relative mt-24 mb-8 w-full lg:hidden">
        <input
          type="search"
          className="text-jacarta-900 placeholder-jacarta-500 focus:ring-accent border-jacarta-100 w-full rounded-2xl border py-3 px-4 pl-10 dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white"
          placeholder="Search"
        />
        <span className="absolute left-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={24}
            height={24}
            className="fill-jacarta-500 h-4 w-4 dark:fill-white"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" />
          </svg>
        </span>
      </form>
      {/* End search form mobile menu  */}

      <nav className="navbar w-full">
        <ul className="flex flex-col lg:flex-row">
          <li className="js-nav-dropdown group relative">
            <button
              onClick={() => mobileCollapse(home.id)}
              className="dropdown-toggle text-jacarta-900 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full"
            >
              <span
                className={
                  isParentPageActive(home.pages, route.asPath)
                    ? "text-accent dark:text-accent"
                    : ""
                }
              >
                {home.name}
              </span>
              <i className="lg:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width={24}
                  height={24}
                  className="h-4 w-4 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                </svg>
              </i>
            </button>
            <ul
              className={`dropdown-menu dark:bg-jacarta-800 left-0 top-[85%] z-10 min-w-[200px] gap-x-4 whitespace-nowrap rounded-xl bg-white transition-all will-change-transform group-hover:visible group-hover:opacity-100 lg:invisible lg:absolute lg:grid lg:translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2 relative ${isCollapse === home.id ? "block" : "hidden"
                }`}
            >
              {home?.pages?.map((page) => (
                <li key={page.id} onClick={() => setToggle(false)}>
                  <Link
                    href={page.path}
                    className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center rounded-xl px-5 py-2 transition-colors"
                  >

                    <span
                      className={`font-display ${isChildrenPageActive(route.asPath, page.path)
                        ? "text-accent dark:text-accent"
                        : "text-jacarta-900"
                        } text-sm dark:text-white`}
                    >
                      {page.name}
                    </span>
                    {page.condition ? (
                      <span className="rounded bg-green py-1 px-2 text-tiny font-bold uppercase leading-none text-white ml-4">
                        new
                      </span>
                    ) : undefined}

                  </Link>
                </li>
              ))}
            </ul>
          </li>

          <li className="group" onClick={() => setToggle(false)}>
            <Link href="/create" >

              <button className="text-jacarta-900 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                <span
                  className={
                    isChildrenPageActive("/create", route.asPath)
                      ? "text-accent dark:text-accent"
                      : ""
                  }
                >
                  Create
                </span>
              </button>

            </Link>
          </li>
        </ul>
      </nav>
      {/* End navbar mobile menu  */}

    </div>

  </>;
}
